import http from '../utils/axios';
import {BASE, pushUrl, config, cb, wxApi, YMJ} from './base';
import axios from 'axios/index';

const WECHAT = 'WECHAT-API/';
const ONLN = 'ONLN-API/';
const CAM = 'CAMPAIGN-API/';
const CON = 'CON-API/';
// 判断是否参加活动
export function apiJoinStatue (params, callback) {
  http(config(BASE + WECHAT + 'cmpgn/store', params)).then(res => cb(res, callback));
}

// 确认参与活动
export function apiCmpgnStoreJoin (params, callback) {
  http(config(BASE + WECHAT + 'cmpgn/store', params, 'POST')).then(res => cb(res, callback));
}

// 领取状态
export function apiCmpgnCoupon (params, callback) {
  http(config(BASE + WECHAT + 'cmpgn/coupon', params)).then(res => cb(res, callback));
}

// 领取券
export function apiCmpgnCouponTake (params, callback) {
  http(config(BASE + WECHAT + 'cmpgn/coupon', params, 'POST')).then(res => cb(res, callback));
}

// 评论列表
export function apiCmpgnListCmmnts (params, callback) {
  http(config(BASE + WECHAT + 'cmpgn/listCmmnts', params)).then(res => cb(res, callback));
}

// 领取优惠券用户列表
export function apiCmpgnListCoupun (params, callback) {
  http(config(BASE + WECHAT + 'cmpgn/listCoupon', params)).then(res => cb(res, callback));
}

// 蓝粉惠套餐订单信息查询
export function apiCmpgnOrder (params, callback) {
  http(config(BASE + WECHAT + 'cmpgn/order', params)).then(res => cb(res, callback));
}

// 蓝粉惠新增订单
export function apiCmpgnAddOrder (params, callback) {
  http(config(BASE + WECHAT + 'cmpgn/addOrder', params)).then(res => cb(res, callback));
}

// 蓝粉慧参与人数
export function apiCmpgnPartNum (params, callback) {
  http(config(BASE + WECHAT + 'cmpgn/partNum', params)).then(res => cb(res, callback));
}

// 我的福利
export function apiMyWelfare (params, callback) {
  http(config(BASE + WECHAT + 'myWelfare', params)).then(res => cb(res, callback));
}

// 积分记录查询
export function apiPointHistory (params, callback) {
  http(config(BASE + WECHAT + 'listPointsChngByOpenId', params)).then(res => cb(res, callback));
}

// 积分在路上和邀请失败列表
export function apiPointOther (params, callback) {
  http(config(BASE + WECHAT + 'listCustPointsWayAndFail', params)).then(res => cb(res, callback));
}

// 积分商城
export function apiShopProds (params, callback) {
  http(config(BASE + WECHAT + 'product/list', params, 'POST')).then(res => cb(res, callback));
}

// 订单保存
export function apiOrderSave (params, callback) {
  http(config(BASE + WECHAT + 'saveDorder', params, 'PUT')).then(res => cb(res, callback));
}

// 取消订单
export function apiCancelDorder (params, callback) {
  http(config(BASE + WECHAT + 'cancelDorder', params, 'DELETE')).then(res => cb(res, callback));
}

// 收货地址
export function apiAddressList (params, callback) {
  http(config(BASE + WECHAT + 'listDcustAddress', params)).then(res => cb(res, callback));
}

// 新增收获地址
export function apiAddressSave (params, callback) {
  http(config(BASE + WECHAT + 'saveDcustAddress', params, 'PUT')).then(res => cb(res, callback));
}

// 删除收获地址
export function apiAddressDelt (params, callback) {
  http(config(BASE + WECHAT + 'deleteDcustAddress', params, 'DELETE')).then(res => cb(res, callback));
}

// 活动详情
export function apiGetCmpgnDetail (params, callback) {
  http(config(BASE + WECHAT + 'campaign/getCampaignTypeRule', params)).then(res => cb(res, callback));
}

// 获取门店详情
export function apiGetStoreBasic (params, callback) {
  http(config(BASE + WECHAT + 'getStoreBasic', params)).then(res => cb(res, callback));
}

// 活动订单-新增
export function apiCmpgnOrd (params, callback) {
  http(config(BASE + WECHAT + 'cmpgn/order', params, 'POST')).then(res => cb(res, callback));
}
// 会员基本信息
export function apiCusInfo (params, callback) {
  http(config(BASE + WECHAT + 'cust/info', params)).then(res => cb(res, callback));
}
// 检查二维码状态
export function apiQrcode (params, callback) {
  http(config(BASE + WECHAT + 'cmpgn/qrcode', params)).then(res => cb(res, callback));
}
// 晶钻美肤开启通道
export function apiOpenChannel (params, callback) {
  http(config(BASE + WECHAT + 'cmpgn/openChannel', params)).then(res => cb(res, callback));
}
// 查询充值权益
export function apiQrcodeInfo (params, callback) {
  http(config(BASE + WECHAT + 'cmpgn/getKinSkinCouponBal', params)).then(res => cb(res, callback));
}
// 晶钻美肤充值
export function apiSaveKinSkinOrder (params, callback) {
  http(config(BASE + WECHAT + 'cmpgn/saveKinSkinOrder', params, 'POST')).then(res => cb(res, callback));
}
// 检查权益是否被锁定
export function apiCheckPrvlgLock (params, callback) {
  http(config(BASE + WECHAT + 'checkPrvlgLock', params, 'GET')).then(res => cb(res, callback));
}
// 拼团活动
// 拼团详情-代理商
export function apiGroupAgentDetail (params, callback) {
  http(config(BASE + WECHAT + 'groupAgent/detail', params, 'GET')).then(res => cb(res, callback));
}
// 新增拼团订单-代理商
export function apiGroupAgentOrder (params, callback) {
  http(config(BASE + WECHAT + 'groupAgent/order', params, 'POST')).then(res => cb(res, callback));
}
// 新增拼团订单-客户
export function apiGroupCustOrder (params, callback) {
  http(config(BASE + WECHAT + 'groupCust/order', params, 'POST')).then(res => cb(res, callback));
}
// 会员拼团-我的概览
export function apiCustGrpSummary (params, callback) {
  http(config(BASE + WECHAT + 'groupCust/getCustGrpSummary', params, 'GET')).then(res => cb(res, callback));
}
// 会员拼团订单-订单详情
export function apiGroupCustOrderDetail (params, callback) {
  http(config(BASE + WECHAT + 'groupCust/order', params, 'GET')).then(res => cb(res, callback));
}
// 会员拼团-团详情
export function apiGroupDetail (params, callback) {
  http(config(BASE + WECHAT + 'groupCust/getCustGrpDetail', params, 'GET')).then(res => cb(res, callback));
}
// 会员拼团-领取团长奖励
export function apiGetGrpLeadCoupon (params, callback) {
  http(config(BASE + WECHAT + 'groupCust/getGrpLeadCoupon', params, 'GET')).then(res => cb(res, callback));
}
// 会员拼团-获取店铺成团数量
export function apiGetGrpCnt (params, callback) {
  http(config(BASE + WECHAT + 'groupCust/getGrpCnt', params, 'GET')).then(res => cb(res, callback));
}
// 会员拼团-个人中心-我的拼团
export function apiGetMyGroup (params, callback) {
  http(config(BASE + WECHAT + 'groupCust/myGroup', params, 'GET')).then(res => cb(res, callback));
}
// 会员拼团-获取产品门店活动价
export function apiGetPrdctStoreCmpgnPrice (params, callback) {
  http(config(BASE + WECHAT + 'cmpgn/getPrdctStoreCmpgnPrice', params, 'GET')).then(res => cb(res, callback));
}
// 焕肤活动详情
export function apiGetApperanceDetail (params, callback) {
  http(config(BASE + WECHAT + 'cmpgn/getDetl4ReplaceColor', params, 'GET')).then(res => cb(res, callback));
}
// 开启焕肤活动
export function apiJoinApperance (params, callback) {
  http(config(BASE + WECHAT + 'cmpgn/replaceColor', params, 'GET')).then(res => cb(res, callback));
}
// 修改焕肤活动价格
export function apiChangePrice (params, callback) {
  http(config(BASE + WECHAT + 'cmpgn/replaceColor', params, 'POST')).then(res => cb(res, callback));
}
// 焕肤是否购买
export function apiApperanceStatue (params, callback) {
  http(config(BASE + WECHAT + 'cmpgn/replaceColorSttus', params, 'GET')).then(res => cb(res, callback));
}
// 取消订单
export function apiCancelOrder (params, callback) {
  http(config(BASE + WECHAT + 'cmpgn/cancelOrder', params, 'GET')).then(res => cb(res, callback));
}
// 我的考试
export function apiMyExam (params, callback) {
  http(config(BASE + WECHAT + 'conference/getCustExamSummary', params, 'GET')).then(res => cb(res, callback));
}
// 我的考试
export function apiStartExam (params, callback) {
  http(config(BASE + WECHAT + 'conference/startCustExam', params, 'POST')).then(res => cb(res, callback));
}
// 开启黄褐斑活动
export function apiOpenChloasma (params, callback) {
  http(config(BASE + WECHAT + 'speckle/startCmpgn', params, 'GET')).then(res => cb(res, callback));
}
// 黄褐斑活动开启状态
export function apiChloasmaStatus (params, callback) {
  http(config(BASE + WECHAT + 'speckle/status', params, 'GET')).then(res => cb(res, callback));
}
// 黄褐斑预约用户列表
export function apiChloasmaList (params, callback) {
  http(config(BASE + WECHAT + 'speckle/listUserByPage', params, 'GET')).then(res => cb(res, callback));
}
// 用户黄褐斑预约状态
export function apiChloasmaBook (params, callback) {
  http(config(BASE + WECHAT + 'speckle/bookingSttus', params, 'GET')).then(res => cb(res, callback));
}
// 活动详情
export function apiCampaign (params, callback) {
  http(config(BASE + WECHAT + 'campaign', params, 'GET')).then(res => cb(res, callback));
}
// 身份验证
export function apiValidIdNum (params, callback) {
  http(config(BASE + WECHAT + 'partpnt/validIdNum', params, 'GET')).then(res => cb(res, callback));
}
// 获取参会人员信息
export function apiGetParent (params, callback) {
  http(config(BASE + WECHAT + 'partpnt/getPartpnt', params, 'GET')).then(res => cb(res, callback));
}
// 保存参会人员信息
export function apiSaveParent (params, callback) {
  http(config(BASE + WECHAT + 'partpnt/savePartpnt', params, 'PUT')).then(res => cb(res, callback));
}
// 获取会议问题
export function apiListConQst (params, callback) {
  http(config(BASE + WECHAT + 'partpnt/listConQstn', params, 'GET')).then(res => cb(res, callback));
}
// 获取参会费
export function apiCalcFarePrice (params, callback) {
  http(config(BASE + WECHAT + 'partpnt/calcFarePrice', params, 'POST')).then(res => cb(res, callback));
}
// 获取会议详情
export function apiGetConference (params, callback) {
  http(config(BASE + WECHAT + 'management/conference', params, 'GET')).then(res => cb(res, callback));
}
// 根据agentId获取代理商信息
export function apiGetAgentInfoByAgentId (params, callback) {
  http(config(BASE + WECHAT + 'getAgentInfoByAgentId', params, 'GET')).then(res => cb(res, callback));
}
// 我的抽奖总览
export function apiRaffleSummary (params, callback) {
  http(config(BASE + WECHAT + 'cmpgn/myRaffleSummary', params, 'GET')).then(res => cb(res, callback));
}
// 抽奖
export function apiRaffle (params, callback) {
  http(config(BASE + WECHAT + 'cmpgn/raffle', params, 'GET')).then(res => cb(res, callback));
}
// 保存收货地址
export function apiRaffleAddr (params, callback) {
  http(config(BASE + WECHAT + 'cmpgn/saveRaffleAddr', params, 'POST')).then(res => cb(res, callback));
}
// 三喷贺岁导客
export function apiBookAlloc (params, callback) {
  http(config(BASE + ONLN + 'bookAlloc', params, 'PUT')).then(res => cb(res, callback));
}
// 三喷贺岁导客
export function apiNewBookAlloc (params, callback) {
  http(config(BASE + WECHAT + 'bookAlloc', params, 'PUT')).then(res => cb(res, callback));
}
// 天津订单
export function apiTemCmpgnOrder (params, callback) {
  http(config(BASE + WECHAT + 'tempCmpgn/order', params, 'POST')).then(res => cb(res, callback));
}
// 验证是否购买过
export function apiTemCmpgnOrderCheck (params, callback) {
  http(config(BASE + WECHAT + 'tempCmpgn/orderCheck', params, 'GET')).then(res => cb(res, callback));
}
// 方案列表
export function apiConListConProposalByPage (params, callback) {
  http(config(BASE + WECHAT + 'con/listConProposalByPage', params, 'GET')).then(res => cb(res, callback));
}
// 注册成功发送活动推送
export const sendUrl = pushUrl + 'wxActive/sendOrderMsg';
// 方案详情
export function apiGetConProposal (params, callback) {
  http(config(BASE + WECHAT + 'con/getConProposal', params, 'GET')).then(res => cb(res, callback));
}
// 保存方案订单
export function apiSavePartpntOrder (params, callback) {
  http(config(BASE + WECHAT + 'con/savePartpntOrder', params, 'PUT')).then(res => cb(res, callback));
}
// 获取方案订单总览
export function apiGetOrderPaySummary (params, callback) {
  http(config(BASE + WECHAT + 'con/getOrderPaySummary', params, 'GET')).then(res => cb(res, callback));
}
// 调整分期金额
export function apiChngOrderInstlAmnt (params, callback) {
  http(config(BASE + WECHAT + 'con/chngOrderInstlAmnt', params, 'GET')).then(res => cb(res, callback));
}
// 参会人员订单列表
export function apiListPartpntOrderByPage (params, callback) {
  http(config(BASE + WECHAT + 'con/listPartpntOrderByPage', params, 'GET')).then(res => cb(res, callback));
}
// 参会人员订单详情
export function apiGetPartpntOrder (params, callback) {
  http(config(BASE + WECHAT + 'con/getPartpntOrder', params, 'GET')).then(res => cb(res, callback));
}
// 签单跑马灯
export function apiListOrderMarquee (params, callback) {
  http(config(BASE + WECHAT + 'con/listOrderMarquee', params, 'GET')).then(res => cb(res, callback));
}
// 提交分期
export function apiOrderSubmit (params, callback) {
  http(config(wxApi + YMJ + 'orderSubmit', params, 'POST')).then(res => cb(res, callback));
}
// 获取品牌授权
export function apiGetBrandAuth (params, callback) {
  http(config(BASE + WECHAT + 'partpnt/getBrandAuth', params, 'GET')).then(res => cb(res, callback));
}
// 确认品牌授权
export function apiPUTBrandAuth (params, callback) {
  http(config(BASE + WECHAT + 'partpnt/saveBrandAuth', params, 'PUT')).then(res => cb(res, callback));
}
// 分期还款计划表
export function apiOrderPlan (params, callback) {
  http(config(wxApi + YMJ + 'orderPlan', params, 'GET')).then(res => cb(res, callback));
}
// 分期订单列表
export function apiListInstlOrderByPage (params, callback) {
  http(config(BASE + WECHAT + 'con/listInstlOrderByPage', params, 'GET')).then(res => cb(res, callback));
}
// 分期订单详情
export function apiInstlOrder (params, callback) {
  http(config(BASE + WECHAT + 'con/instlOrder', params, 'GET')).then(res => cb(res, callback));
}
// 我要还款
export function apiOrderDetl (instlId) {
  return wxApi + YMJ + 'orderDetl?instlId=' + instlId;
}
// 抽奖活动统计
export function apiRaffleStatistics (params, callback) {
  http(config(BASE + CAM + 'getRaffleAndPayTimes', params, 'GET')).then(res => cb(res, callback));
}
// 签订赠品列表
export function apiGiftList (params, callback) {
  http(config(BASE + WECHAT + 'listConGiftOption', params, 'GET')).then(res => cb(res, callback));
}
// 选择赠品
export function apichooseGift (params, callback) {
  http(config(BASE + WECHAT + 'chooseConGiftOption', params, 'GET')).then(res => cb(res, callback));
}
// 我的考试
export function apiGetCustExamSummary (params, callback) {
  http(config(BASE + WECHAT + 'conference/getCustExamSummary', params, 'GET')).then(res => cb(res, callback));
}
// 开始考试
export function apiStartCustExam (params, callback) {
  http(config(BASE + WECHAT + 'conference/startCustExam', params, 'POST')).then(res => cb(res, callback));
}
// 证书领取
export function apiGetCustExamCert (params, callback) {
  http(config(BASE + WECHAT + 'con/getCustExamCert', params, 'GET')).then(res => cb(res, callback));
}
// 抽奖总览 - 感恩同行
export function apiMyRaffleSummary (params, callback) {
  http(config(BASE + WECHAT + 'con/myRaffleSummary', params, 'GET')).then(res => cb(res, callback));
}
// 抽奖 - 感恩同行
export function apiConRaffle (params, callback) {
  http(config(BASE + WECHAT + 'con/raffle', params, 'GET')).then(res => cb(res, callback));
}
// 中奖跑马灯 - 感恩同行
export function apiListRaffleMarquee (params, callback) {
  http(config(BASE + WECHAT + 'con/listRaffleMarquee', params, 'GET')).then(res => cb(res, callback));
}
// 检查门店活动权限是否已经开启
export function apiCheckCmpgnPrvlg (params, callback) {
  http(config(BASE + WECHAT + 'checkCmpgnPrvlg', params, 'GET')).then(res => cb(res, callback));
}
