import { apiExamStop } from '@/api/h5'

export default {
  data () {
    return {
      isX: false
    }
  },
  methods: {
    // 判断是否是iphoneX
    isIPhoneX (fn) {
      var u = navigator.userAgent
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        if (screen.height === 812 && screen.width === 375) {
          //是iphoneX
          alert(`是iphoneX${screen.width}${screen.height}`)
          console.log('是iphoneX', window.navigator.appVersion)
          this.isX = true
        } else {
          this.isX = false
          //不是iphoneX
          alert('不是iphoneX')
          console.log('不是iphoneX')
        }
      }
    },
    // 重置答题的接口
    resetAnswer (info) {
      // 判断是否为空
      // 2.3.2 重新赋值TESTPAGER
      this.resetInfo(info)
      // 3. 更新缓存
      localStorage.setItem('TESTPAGER', JSON.stringify(info))
    },
    // 格式化数据
    formatAnswerByRes (resData) {
      let info = JSON.parse(JSON.stringify(resData))
      return {
        config: {
          id: info.test_paper_info.type,
          test_id: info.test_id,
          title: info.test_paper_info.id,
          title_name: info.test_paper_info.title
        },
        data: {
          errorNum: 0,
          notList: [],
          test: [...info.test],
          test_id: info.test_id,
          trueNum: 0,
          test_paper_info: info.test_paper_info
        },
        info: null,
        other: {
          [info.test_paper_info.id]: true
        }
      }
    },
    // 重置选项
    resetAnswerData (newResData) {
      let info = JSON.parse(JSON.stringify(newResData))
      info.data.yetList = []
      info.data.notList = []
      info.data.test.forEach((el, i) => {
        info.data.notList.push(i)
        el.status = ''
        el.yetChoose = el.question_type === 3 ? [] : ''
        el.isCanClick = true
        el.isShowParse = false
        el.showSubmitBtn = true
        el.menu = {}
        console.log(el, i)
        for (let k in el) {
          if (el.hasOwnProperty(k) && k.indexOf('option') === 0 && el[k] && k !== 'option') {
            el.menu[k.replace('option', '')] = {
              label: el[k],
              className: ''
            }
          }
        }
        // for (let k in el.menu) {
        //   el.menu[k].className = ''
        // }
      })
      info.data.errorNum = 0
      info.data.trueNum = 0
      return info
    },
    // 判断选项的对错
    judgeOption (res) {
      res.test.forEach(el => {
        console.log('判断选项的对错 => el', el)
        if ((el.hasOwnProperty('option') && el.option)) {
          if (Array.isArray(el.option)) {
            if ([1, 2].includes(el.question_type)) {
              // 判断或单选
              // 这只是单选
              if (el.yetChoose === el.answer[0]) {
                // 选对了
                el.menu[el.yetChoose].className = 'option-true'
              } else if (el.yetChoose !== el.answer[0]) {
                // 选错了
                el.menu[el.yetChoose].className = 'option-error'
              }
            }
            if ([3].includes(el.question_type)) {
              // 多选
              if (Array.isArray(el.yetChoose) && el.yetChoose.length > 0) {
                if (JSON.stringify(el.yetChoose) === JSON.stringify(el.answer)) {
                  el.answer.forEach(al => {
                    el.menu[al].className = 'option-true'
                  })
                } else {
                  el.yetChoose.forEach(bl => {
                    el.menu[bl].className = 'option-error'
                  })
                }
              }
            }
          }
        }
      })
      return res
    },
    resetAgainAnswer2 (info) {
      // 1. 格式化接口返回的数据
      let formatInfo = this.formatAnswerByRes(info)
      // 2. 重置1.的数据
      let reInfo = this.resetAnswerData(formatInfo)
      console.log('reInfo', reInfo)
      // 3. 更新缓存
      localStorage.setItem('TESTPAGER', JSON.stringify(reInfo))
      return reInfo
    },
    // 初始化info.data
    resetInfo (info) {
      console.log('初始化info.data resetInfo (info)', info)
      info.data.notList = []
      info.data.yetList = []
      info.data.errorNum = 0
      info.data.trueNum = 0
      info.data.test.forEach((el, i) => {
        info.data.notList.push(i)
        el.status = ''
        el.yetChoose = el.question_type === 3 ? [] : ''
        el.isCanClick = true
        el.isShowParse = false
        el.showSubmitBtn = true
        for (let k in el.menu) {
          el.menu[k].className = ''
        }
      })
    },
    // 暂停
    getExamStopStt (test_id, subject_id) {
      let name = localStorage.getItem('name')
      let wx_uid = localStorage.getItem('wx_uid')
      return new Promise((res_, rej_) => {
        apiExamStop({ test_id, subject_id, name, wx_uid }, res => {
          if (res.data.code === 200) {
            res_(res.data)
          } else {
            rej_(res.data)
          }
        })
      })
    }
  }
}
