import axios from 'axios'
import { showFullScreenLoading, tryHideFullScreenLoading } from './loading'
import router from '../router'
import { Toast } from 'vant'

Toast.allowMultiple()

function toastBox (msg = '请求失败') {
  let toast = Toast.fail({
    message: msg,
    duration: 0
  })
  setTimeout(() => {
    toast.clear()
  }, 1500)
}

let http = axios.create({
  baseURL: '/api/',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  },
  transformRequest: [function(data) {
    // console.log('transformRequest => data', data)
    // console.log('transformRequest => data', typeof data)
    return data
  }]
})
http.defaults.timeout = 15000
http.interceptors.request.use(function(config) {
  // console.log('config', config)
  let openId = localStorage.getItem('openid')
  if (openId) config.headers.openId = openId
  let currentTenantId = localStorage.getItem('currentTenantId')
  if (currentTenantId) config.headers.currentTenantId = currentTenantId
  // 通过session控制是否需要加载框
  let loading = localStorage.getItem('loading')
  if (!config.showLoading && loading === '0') {
    config.showLoading = true
    showFullScreenLoading()
  }
  return config
}, function(error) {
  return Promise.reject(error)
})
http.interceptors.response.use(function(response) {
  if (response.config.showLoading) {
    tryHideFullScreenLoading()
  }
  let code = response.data.code
  if (code === 200) {
    return response
  } else {
    toastBox(response.data.message || response.data.msg)
    return response
    // return Promise.reject(response);
  }
}, function(error) {
  toastBox()
  tryHideFullScreenLoading()
  router.push({ path: '/error' })
  return Promise.reject(error)
})

export default http
