import { getToken } from '@/utils/auth'

let BASE, wxDomain, wxApi, diagImg, payFiled, pushUrl, YMJ
switch (process.env.NODE_ENV) {
  case 'production':
    BASE = 'https://erp.dijianggroup.com/api/'
    wxApi = 'https://erp.dijianggroup.com/api/'
    wxDomain = 'https://erp.dijianggroup.com/api/'
    diagImg = 'https://erp.dijianggroup.com/api/'
    payFiled = 'wxpay'
    pushUrl = 'https://erp.dijianggroup.com/api/'
    YMJ = 'Ymj/'
    // BASE = 'https://apisit.web.dijianggroup.com/'
    break
  case 'uat':
    BASE = 'https://test.erp.dijianggroup.com/api/'
    wxApi = 'https://test.erp.dijianggroup.com/api/'
    wxDomain = 'https://test.erp.dijianggroup.com/api/'
    diagImg = 'https://test.erp.dijianggroup.com/api/'
    payFiled = 'wxpay'
    pushUrl = 'https://test.erp.dijianggroup.com/api/'
    YMJ = 'Ymjtest/'
    break
  case 'development':
    BASE = 'https://test.erp.dijianggroup.com/api/'
    wxApi = 'https://test.erp.dijianggroup.com/api/'
    wxDomain = 'https://test.erp.dijianggroup.com/api/'
    diagImg = 'https://test.erp.dijianggroup.com/api/'
    payFiled = 'wxpay'
    pushUrl = 'https://test.erp.dijianggroup.com/api/'
    YMJ = 'Ymjtest/'
    break
  default:
    BASE = 'https://test.erp.dijianggroup.com/api/'
    wxApi = 'https://test.erp.dijianggroup.com/api/'
    wxDomain = 'https://test.erp.dijianggroup.com/api/'
    diagImg = 'https://test.erp.dijianggroup.com/api/'
    payFiled = 'wxtest'
}

let uploadUrl = BASE + 'BASE-API/fileUpload'
let shopFileUpload = BASE + 'BASE-API/shopFileUpload'

let smsType = 14
let imgDomain = 'https://dijianggroup.oss-cn-hangzhou.aliyuncs.com/'
let token = getToken()

function config (url, params, method = 'GET') {
  return {
    method: method,
    url: url,
    data: method === 'POST' || method === 'PUT' ? JSON.stringify(params) : null,
    params: method === 'GET' || method === 'DELETE' ? params : null
  }
}

let cb = (res, callback) => {
  if (res && res.data) {
    if (typeof callback === 'function') callback(res)
  }
}

export {
  BASE,
  config,
  cb,
  uploadUrl,
  token,
  imgDomain,
  smsType,
  shopFileUpload,
  wxDomain,
  wxApi,
  diagImg,
  payFiled,
  pushUrl,
  YMJ
}
