import {imgDomain, smsType} from '@/api/base.js';
import {apiGetStoreBasic} from '@/api/CAM-API';
import {apiUser} from '@/api/BASE-API';
import Cookie from 'js-cookie';
import {showFullScreenLoading, tryHideFullScreenLoading} from '@/utils/loading';
export default {
  data () {
    return {
      imgDomain,
      smsType,
      errorMask: false,
      errorStr: '',
      list: [],
      login: undefined,
      pageConfig: {
        current: 1,
        total: 30
      },
      loading: false,
      finished: false,
      defaultImg: {
        storeLogo: 'saas/2c90071b670c3ce001670c9e5c2b0005.png',
        storeBanner: 'saas/2c90071b670c3ce001670c83bcd10003.png'
      }
    };
  },
  filters: {
    dateFormatter (date, formatter) {
      date = (date ? new Date(Number(date)) : new Date());
      let Y = date.getFullYear() + '';
      let M = date.getMonth() + 1;
      let D = date.getDate();
      let H = date.getHours();
      let m = date.getMinutes();
      let s = date.getSeconds();
      return formatter.replace(/YYYY|yyyy/g, Y)
        .replace(/YY|yy/g, Y.substr(2, 2))
        .replace(/MM/g, (M < 10 ? '0' : '') + M)
        .replace(/DD/g, (D < 10 ? '0' : '') + D)
        .replace(/HH|hh/g, (H < 10 ? '0' : '') + H)
        .replace(/mm/g, (m < 10 ? '0' : '') + m)
        .replace(/ss/g, (s < 10 ? '0' : '') + s);
      // return this.dateFormatters(date, formatter)
    }
  },
  methods: {
    dateFormatters (date, formatter) {
      date = (date ? new Date(Number(date)) : new Date());
      let Y = date.getFullYear() + '';
      let M = date.getMonth() + 1;
      let D = date.getDate();
      let H = date.getHours();
      let m = date.getMinutes();
      let s = date.getSeconds();
      return formatter.replace(/YYYY|yyyy/g, Y)
        .replace(/YY|yy/g, Y.substr(2, 2))
        .replace(/MM/g, (M < 10 ? '0' : '') + M)
        .replace(/DD/g, (D < 10 ? '0' : '') + D)
        .replace(/HH|hh/g, (H < 10 ? '0' : '') + H)
        .replace(/mm/g, (m < 10 ? '0' : '') + m)
        .replace(/ss/g, (s < 10 ? '0' : '') + s);
      // 使用方法dateFormatter('YYYY年-MM月-DD日 HH:mm:ss')
    },
    stop () {},
    // 处理点击事件错位
    resetClick () {
      setTimeout(function () {
        var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
        window.scrollTo(0, Math.max(scrollHeight - 1, 0));
      }, 100);
    },
    // 顶部提示
    notify (msg) {
      this.$notify({
        message: msg,
        duration: 1000,
        color: 'white',
        background: '#ff3f45'
      });
    },
    // 跳转页面
    createPage (page) {
      let {name, params, query} = page;
      this.$router.push({name, params, query});
      Cookie.remove('rediUrl');
    },
    // 获取元数据名称
    getCodeDesc (code, item) {
      if (!Array.isArray(item) || code === undefined || code === '') return '';
      let arr = item.filter(ele => ele.value === code);
      if (arr.length > 0) {
        return arr[0].label;
      } else {
        return '';
      }
    },
    // 显示错误
    showErr (str) {
      this.errorMask = true;
      this.errorStr = str;
    },
    // 关闭错误提示
    closeErr (val) {
      this.errorMask = val;
    },
    // 获取用户信息
    getUserInfo () {
      if (!window.localStorage.getItem('openid')) return;
      // alert('cust接口:' + localStorage.getItem('currentTenantId'));
      apiUser(res => {
        if (res.data.errcode === 0) {
          let data = res.data.data;
          if (data) {
            this.$store.dispatch('getUserInfo', data);
          } else {
            if (this.login) {
              this.$router.push({name: 'login'});
            }
          }
        } else {
          this.$router.push({name: 'login'});
        }
      });
    },
    // 处理图片路径
    realAvatar (avatar) {
      if (!avatar) return '';
      if (avatar.includes('http://') || avatar.includes('https://')) {
        return avatar;
      } else {
        return this.imgDomain + avatar;
      }
    },
    // 获取门店信息
    getStoreBasic (storeId) {
      return new Promise((resolve) => {
        apiGetStoreBasic({storeId}, res => {
          console.log(res);
          if (res.data.errcode === 0) {
            resolve(res.data.data);
          }
        });
      });
    },
    // 订单状态
    getOrdSttu (ordSttusCode) {
      let str = '';
      switch (ordSttusCode) {
        case '50501':
          str = '待确认';
          break;
        case '50502':
          str = '支付中';
          break;
        case '50503':
          str = '已完成 ';
          break;
        case '50504':
          str = '已取消';
          break;
        default:
          str = '未支付';
      }
      return str;
    },
    // 分期状态
    getInsSttu (instlSttusCode) {
      let str = '';
      switch (instlSttusCode) {
        case '60401':
          str = '未提交';
          break;
        case '60402':
          str = '待审核';
          break;
        case '60403':
          str = '待补件';
          break;
        case '60404':
          str = '分期失败';
          break;
        case '60405':
          str = '分期成功';
          break;
        case '60406':
          str = '分期未通过';
          break;
        case '60407':
          str = '分期失效';
          break;
        case '60408':
          str = '资料已提交';
          break;
        default:
          str = '已取消';
      }
      return str;
    },
    // 分期状态
    getOnlnSttu (sttu) {
      let str = '';
      switch (sttu) {
        case '71501':
          str = '预约中';
          break;
        case '71502':
          str = '已到店';
          break;
        case '71503':
          str = '已取消';
          break;
        default:
          str = '已超时';
      }
      return str;
    },
    // 加载数据
    loadList (data, count) {
      this.loading = false;
      let pageNo = this.params.pageNo;
      if (pageNo === 1) {
        this.list = [...data];
      } else {
        this.list.push(...data);
      }
      let len = this.list.length;
      this.$set(this.params, 'pageNo', pageNo++);
      this.params.pageNo = pageNo++;
      this.pageConfig.current = pageNo;
      this.pageConfig.total = count;
      if (len >= count) {
        this.finished = true;
      } else {
        this.finished = false;
      }
    },
    // 清除实时输入时的前后空格
    setTrim (field) {
      let props;
      let len;
      let newphoestr;
      let item = this;
      if (field) {
        props = field.split('.');
        len = props.length;
        for (let i = 0; i < len - 1; i++) {
          item = item[props[i]];
        }
        newphoestr = (item[props[len - 1]]).replace(/^ +| +$/g, '');
        this.$set(item, props[len - 1], newphoestr);
      }
    },
    resetParams () {
      let params = this.params;
      for (let prop in params) {
        if (prop !== 'pageNo') {
          params[prop] = '';
        }
        params.pageNo = 1;
      }
      this.pageConfig.current = 1;
      this.pageConfig.total = 0;
    },
    // 验证值是否有效
    isValid (value) {
      if (value === '') { return false; }
      if (value === null) { return false; }
      if (value === undefined) { return false; }
      if (value === 0) { return false; }
      if (value === false) { return false; }
      return true;
    },
    // 验证值是否有效
    isValidNot0 (value) {
      if (value === '') { return false; }
      if (value === null) { return false; }
      if (value === undefined) { return false; }
      if (value === false) { return false; }
      return true;
    },
    // 图片预加载
    preLoadImg (arr) {
      showFullScreenLoading();
      return new Promise((resolve) => {
        let len = arr.length;
        let num = 0;
        arr.forEach(item => {
          let img = new Image();
          img.src = item;
          if (img.complete) {
            num++;
            if (num === len) {
              console.log('complete', num);
              tryHideFullScreenLoading();
              resolve(num);
            }
          } else {
            img.onload = function () {
              num++;
              if (num === len) {
                console.log('onload', num);
                tryHideFullScreenLoading();
                resolve(num);
              }
            };
            img.onerror = function () {
              num++;
              if (num === len) {
                console.log('onerror', num);
                tryHideFullScreenLoading();
                resolve(num);
              }
            };
          }
        });
      });
    }
  },
  computed: {
    opendId () {
      return localStorage.getItem('openid');
    },
    phoneNo () {
      return localStorage.getItem('phone');
    },
    nickname () {
      return localStorage.getItem('nickname');
    },
    avatar () {
      return localStorage.getItem('headimgurl');
    }
  }
  // beforeRouteEnter (to, from, next) {
  //   let {title} = to.meta
  //   title = title || '珂盟美业'
  //   if (title) document.title = title
  //   next()
  // },
};
