<template>
  <div id="app">
    <!--    <transition name="fade">-->
    <router-view v-if="isRouter"></router-view>
    <div>
      <p class="mtb-5" v-for="(item,i) in msg" :key="i">{{item}}</p>
    </div>
    <!--    </transition>-->
    <dj-footer></dj-footer>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { getQueryString } from '@/utils/util'
import mixin from '@/mixins/mixins'
import Cookie from 'js-cookie'
import { wxDomain } from '@/api/base.js'
import { apiGetToken } from '@/api/BASE-API.js'
import { apiTypeName, apiAuth } from '@/api/h5.js'
import footer from '@/components/config/basis/footer.vue'
import { cb, wxApi } from './api/base'

export default {
  mixins: [mixin],
  components: {
    djFooter: footer
  },
  data () {
    return {
      indicator: null,
      value: 2,
      isRouter: false,
      msg: []
    }
  },
  created () {
    console.log(process.env.NODE_ENV)
    this.isRouter = true
  },
  methods: {
    goToHome () {
      let rediUrl = getQueryString('rediUrl') || Cookie.get('rediUrl')
      if (rediUrl) {
        this.$router.push({ path: decodeURIComponent(rediUrl) })
      } else {
        let code = getQueryString('code')
        if (code) window.location.href = wxDomain
      }
    },
    // 判断页面的状态
    judgePageStt () {
      document.addEventListener('visibilitychange', () => {
        console.log('document.hidden', document.hidden)
        if (document.hidden) {
          // audio.pause()
          // video.pause()
          this.msg.push(`${dayjs().format('YYYY-MM-DD HH:mm:ss.SSS A')}页面执行了隐藏`)
          console.log('hidden')
        } else {
          console.log('no hide')
          this.msg.push(`${dayjs().format('YYYY-MM-DD HH:mm:ss.SSS A')}页面执行了显示`)
          setTimeout(() => {
            // bgMusic.play()
            // video.play()
          }, 2000)
        }
      })
    }
  },
  mounted () {
    wx.closeWindow()
    // this.judgePageStt()
  },
  computed: {
    listMeta () {
      // 新建时处理元数据
      let listMeta = this.$store.state.listMeta
      return listMeta
    }
  },
  watch: {
    listMeta: {
      handler (n, o) {
      },
      deep: true
    },
    '$route' () {
      localStorage.setItem('loading', '0')
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/css/base.scss';
@import '@/assets/css/basev2.0.scss';

#app {
  /*background: $color-bg url("../static/images/tabbar_bgc.png") repeat-x 50% 0;
  min-height: 100vh;*/
  /*overflow: auto;*/

  background: $color-bg url("../static/images/tabbar_bgc.png") no-repeat 0 0 ;
  min-height: 100vh;
  overflow: auto;
  background-origin: border-box;
  background-clip: border-box;
  background-size: 100%;
}
</style>
