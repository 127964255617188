import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '*',
      redirect (to) {
        if (to.path === '/') {
          return '/index'
        } else {
          return '/wait'
        }
      }
    },
    {
      name: 'wait',
      path: '/wait',
      component: () => import('@/components/kmWait')
    },
    // 首页
    {
      name: 'loading',
      path: '/loading',
      component: () => import('@/components/index/loading'),
      meta: {
        login: false
      }
    },
    // 首页
    {
      name: 'index',
      path: '/index',
      component: () => import('@/components/index/index'),
      meta: {
        login: false
      }
    },
    // 我的记录
    {
      name: 'user',
      path: '/user',
      component: () => import('@/components/user/index'),
      meta: {
        login: false
      }
    },
    // 答题
    {
      name: 'answer',
      path: '/answer',
      component: () => import('@/components/answer/index'),
      meta: {
        login: false
      }
    },
    // 答题-练习模式
    {
      name: 'practice',
      path: '/practice',
      component: () => import('@/components/answer/practice'),
      meta: {
        login: false
      }
    },
    // 答题-练习模式
    {
      name: 'exam',
      path: '/exam',
      component: () => import('@/components/answer/exam'),
      meta: {
        login: false
      }
    },
    // 答题结果页
    {
      name: 'result',
      path: '/result',
      component: () => import('@/components/answer/result'),
      meta: {
        login: false
      }
    },
    // 答题详情页
    {
      name: 'dtl',
      path: '/dtl',
      component: () => import('@/components/answer/dtl'),
      meta: {
        login: false
      }
    }
  ],
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push (location) {
//   return originalPush.call(this, location).catch(err => err)
// }

export default router
