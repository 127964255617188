<template>
  <div class="bs-bb flex-box jcsb plr-30 ptb-15 tabBarBox h-100">
    <slot class="" name="leftHandle"></slot>
    <slot class="" name="headerTitle"></slot>
    <slot class="" name="rightHandle"></slot>
  </div>
</template>

<script>
export default {
  data () {
    return {
      key: ''
    };
  },
  created () {},
  methods: {
    name () {

    }
  },
};
</script>

<style lang="scss" scoped>
.tabBarBox{
}
</style>
