import http from '../utils/axios'
import { BASE, config, cb } from './base'
// const SERVICE = 'BASE-API/';
// const WECHAT = 'WECHAT-API/';
// const PRD = 'PRD-API/';
// 获取数据
export function apiTypeName (params, callback) {
  http(config(BASE + 'typename', params)).then(res => cb(res, callback))
}

// 开始做题
export function apiBegin (params, callback) {
  http(config(BASE + 'test/begin', params, 'POST')).then(res => cb(res, callback))
}

// 每次答题提交一次
export function apiContinue (params, callback) {
  http(config(BASE + 'test/continue', params, 'POST')).then(res => cb(res, callback))
}

// 答题结束
export function apiEnd (params, callback) {
  http(config(BASE + 'test/end', params, 'POST')).then(res => cb(res, callback))
}

// 我的记录
export function apiMyTest (params, callback) {
  http(config(BASE + 'mytest', params)).then(res => cb(res, callback))
}

// 是否未完成答题
export function apiTestHistory (params, callback) {
  http(config(BASE + 'test/history', params, 'POST')).then(res => cb(res, callback))
}

// 重新答题
export function apiTestAgain (params, callback) {
  http(config(BASE + 'testagain', params, 'POST')).then(res => cb(res, callback))
}

// 鉴权
export function apiAuth (params = {}, callback) {
  http(config(BASE + 'test/userinfo', params)).then(res => cb(res, callback))
}

// 考试模式下的暂停
export function apiExamStop (params = {}, callback) {
  http(config(BASE + 'test/pause', params, 'POST')).then(res => cb(res, callback))
}

// 试卷详情
export function apiExamDtl (params = {}, callback) {
  http(config(BASE + 'test/detail', params)).then(res => cb(res, callback))
}

// 不再提醒
export function apiNotNotice (params, callback) {
  http(config(BASE + 'test/notNotice', params, 'POST')).then(res => cb(res, callback))
}

// 捕获前端异常
export function apiJsError (params, callback) {
  http(config(BASE + 'js/error', params, 'POST')).then(res => cb(res, callback))
}